const setExpiry = (ttl: number): number => {
  const now = new Date();
  return now.getTime() + ttl;
};

const isExpired = (expiry: unknown): boolean => {
  if (typeof expiry !== 'number') return true;

  const now = new Date();
  return now.getTime() > expiry;
};

export { setExpiry, isExpired };
