import { memo } from 'react';
import {
  audioActiveSVG,
  audioInactiveSVG,
  lifeActiveSVG,
  lifeInactiveSVG,
} from '../../resourcesBase64/images/icons';
import { useGame } from '../../context/GameContext';
import './GameInfo.scss';

export const Score = memo(() => {
  const { score } = useGame();

  return <div className="info-score">{score}</div>;
});

export const LifeList = memo(() => {
  const { lives } = useGame();

  return (
    <div className="info-lives">
      {[...Array(3)].map((life, index) => (
        <img
          key={index}
          className="life-image"
          src={lives - index > 0 ? lifeActiveSVG : lifeInactiveSVG}
          alt={`жизнь ${lives - index > 0 ? 'в запасе' : 'сгорела'}`}
        />
      ))}
    </div>
  );
});

const AudioBtn = memo(() => {
  const { isAudioMuted, toggleIsAudioMuted } = useGame();

  return (
    <button className="info-audio" onClick={toggleIsAudioMuted}>
      <img
        className="audio-image"
        src={isAudioMuted ? audioInactiveSVG : audioActiveSVG}
        alt={`${isAudioMuted ? 'включить' : 'выключить'} аудио`}
      />
    </button>
  );
});

interface GameInfoProps {
  isAudioOnly?: boolean;
}

const GameInfo = memo(({ isAudioOnly = false }: GameInfoProps) => (
  <div className="game-info">
    {!isAudioOnly && (
      <>
        <Score />
        <LifeList />
      </>
    )}
    <AudioBtn />
  </div>
));

export default GameInfo;
