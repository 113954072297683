import { useEffect } from 'react';

const useVisualViewportHeight = () => {
  useEffect(() => {
    const setViewportHeight = () => {
      if (window.visualViewport) {
        // Если поддерживается visualViewport API
        const viewportHeight = window.visualViewport.height;
        document.documentElement.style.setProperty('--vh', `${viewportHeight * 0.01}px`);
      } else {
        // На случай, если браузер не поддерживает visualViewport API
        const fallbackHeight = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${fallbackHeight}px`);
      }
    };

    // Вызываем функцию при монтировании компонента
    setViewportHeight();

    // Добавляем обработчики событий для обновления высоты
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', setViewportHeight);
      window.visualViewport.addEventListener('scroll', setViewportHeight);
    } else {
      window.addEventListener('resize', setViewportHeight);
    }

    // Очищаем обработчики событий при размонтировании
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', setViewportHeight);
        window.visualViewport.removeEventListener('scroll', setViewportHeight);
      } else {
        window.removeEventListener('resize', setViewportHeight);
      }
    };
  }, []);
};

export default useVisualViewportHeight;
