import { Text } from 'shared/ui/Text/Text';
import { LifeList } from 'shared/ui/GameInfo/GameInfo';
import { balloonSVG, caseSVG, deskSVG, puddleSVG } from '../../resourcesBase64/images/gameObjects';
import { negativeSVG, positiveSVG } from '../../resourcesBase64/images/icons';
import { useMedia } from 'react-use';
import { RefObject, useEffect, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import './SubjectsPopup.scss';

interface SubjectProps {
  /**
   * @description Название предмета
   */
  subject: string;
  /**
   * @description Ссылка на изображение предмета
   */
  subjectImgUrl: string;
  /**
   * @description Текст, описывающий предмет для alt атрибута img
   */
  subjectAltText: string;
  /**
   * @description Предмет приносит прибыль или уменьшает что-то, например жизнь
   */
  isNegative: boolean;
  /**
   * @description Сообщение попапа;
   */
  text: string;
  /**
   * @description Сообщение попапа;
   */
  text1?: string;
  /**
   * @description Есть ли иконка жизней;
   */
  lifeIcon?: boolean;
}

export const Subject = ({ lifeIcon = false, ...props }: SubjectProps) => {
  const { subject, subjectImgUrl = '', subjectAltText, isNegative, text, text1 } = props;

  return (
    <div className={`popup-subject-content ${subject}`}>
      <img className="popup-subject-img" src={subjectImgUrl} alt={subjectAltText} />
      <div className="popup-subject-text">
        {lifeIcon && <LifeList />}
        <Text size="default" text={text} />
        {text1 && <Text size="default" text={text1} />}
      </div>
      <div className={`popup-subject-icon-${isNegative ? 'negative' : 'positive'}`}>
        <img src={isNegative ? negativeSVG : positiveSVG} alt="" />
      </div>
    </div>
  );
};

export const SubjectsPopup = () => {
  const isFixedScale = useMedia('(max-width: 650px)');
  const isScaleByHeight = useMedia('(min-width: 651px) and (max-width: 1024px)');
  const isScaleByWidth = useMedia('(min-width: 1025px) and (max-width: 1200px)');
  const heroImgRef = useRef<HTMLDivElement>();

  const { width, height } = useResizeObserver({
    ref: heroImgRef as unknown as RefObject<Element>,
  });

  useEffect(() => {
    heroImgRef.current = document.querySelector(
      isScaleByHeight ? '.hero-image-tablet' : '.hero-image'
    ) as HTMLDivElement;
  }, [isScaleByHeight]);

  if ((!heroImgRef || !height) && !isFixedScale) return null;

  return (
    <div
      className="popup-subject-wrapper"
      {...(isScaleByWidth &&
        width && {
          style: {
            transform: `translate(-50%, -50%) scale(${(width / (210 * 2 + 25)) * 0.85})`,
          },
        })}
      {...(isScaleByHeight &&
        height && {
          style: { transform: `scale(${(height / 290) * 0.9})` },
        })}
    >
      <Subject
        subject="case"
        subjectImgUrl={caseSVG}
        subjectAltText="Чемодан"
        isNegative={false}
        text="замедляет скорость"
        text1="может быть промокод"
      />
      <Subject
        subject="desk"
        subjectImgUrl={deskSVG}
        subjectAltText="Доска"
        isNegative={true}
        text="сгорает жизнь"
        lifeIcon={true}
      />
      <Subject
        subject="balloon"
        subjectImgUrl={balloonSVG}
        subjectAltText="Надувной шар"
        isNegative={false}
        text="дополнительные баллы"
      />
      <Subject
        subject="puddle"
        subjectImgUrl={puddleSVG}
        subjectAltText="Лужа"
        isNegative={true}
        text="сгорает жизнь"
        lifeIcon={true}
      />
    </div>
  );
};
