import { FC } from 'react';
import Navigation from './Navigation';
import { TwoSidesLayout } from 'pages';
import './index.scss';

const StartPage: FC = () => {
  return (
    <TwoSidesLayout>
      <Navigation />
    </TwoSidesLayout>
  );
};

export default StartPage;
