import { baseApi, type CustomError } from './baseApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

interface AuthorizeUserRequest {
  username: string;
  password: string;
}

interface AuthorizeUserResponse {
  refresh: string;
  access: string;
}

interface AuthorizeResponse {
  access_token: string;
  refresh_token: string;
  user_created: boolean;
  can_use_referral_code: boolean;
}

interface RefreshRequest {
  refresh: string;
}

export interface RefreshResponse {
  access: string;
}

export interface RegistrationResponse {
  error: boolean;
  error_text: string;
  fields: Record<'nickname' | 'email', string[][]>;
  success: boolean;
}

export interface GetStateResponse {
  state: string;
}

export interface GetUuidResponse {
  uuid: string;
}

// Define a service using a base URL and expected endpoints
export const mainApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getState: builder.query<GetStateResponse, void>({
      query: () => 'get_state/',
      providesTags: ['State'],
    }),
    login: builder.mutation<AuthorizeResponse, FormData>({
      query: (formData) => {
        return {
          url: 'login/',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      transformErrorResponse: (response: FetchBaseQueryError) => {
        if (response.status === 'FETCH_ERROR') {
          response.error = 'Повторите авторизацию в Аэрофлот.бонус';
        }
        return response;
      },
      invalidatesTags: ['Access', 'Refresh', 'UserCreated'],
    }),
    loginCredentials: builder.mutation<AuthorizeUserResponse, AuthorizeUserRequest>({
      query: ({ username, password }) => {
        console.log('username ', username, ' password ', password);
        return {
          url: 'auth/token/',
          method: 'POST',
          body: {
            username,
            password,
          },
        };
      },
      invalidatesTags: ['Access', 'Refresh'],
    }),
    refresh: builder.mutation<RefreshResponse, RefreshRequest>({
      query: (refresh) => {
        console.log('refresh ', refresh);
        return {
          url: 'auth/token/refresh',
          method: 'POST',
          body: {
            refresh,
          },
        };
      },
      invalidatesTags: ['Refresh'],
    }),
    createUser: builder.mutation<RegistrationResponse, FormData>({
      query: (formData) => {
        return {
          url: 'rest/register',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Email', 'Nickname'],
    }),
    updateNickname: builder.mutation<RegistrationResponse, FormData>({
      query: (formData) => {
        console.log('formData ', formData);
        return {
          url: 'rest/change_nickname',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Nickname'],
    }),
    getUuid: builder.query<GetUuidResponse, void>({
      query: () => 'auth/uuid/',
      providesTags: ['Uuid'],
    }),
  }),
});

export const {
  useLazyGetStateQuery,
  useLoginMutation,
  useLoginCredentialsMutation,
  useCreateUserMutation,
  useUpdateNicknameMutation,
  useLazyGetUuidQuery,
} = mainApi;
