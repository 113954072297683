import { useState } from 'react';

const useDeviceType = () => {
  const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(' userAgent ', userAgent);

    const isMobile = /mobile|android|iphone|ipod|iemobile|blackberry|bada/.test(userAgent);
    const isTablet = /tablet|ipad|playbook|macintosh/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    const isIOS = /iphone|ipad|ipod|macintosh/.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    return {
      isMobile,
      isTablet,
      isDesktop,
      isAndroid,
      isIOS,
    };
  };

  // Инициализируем состояние при первом рендере
  const [deviceType, setDeviceType] = useState(getDeviceType);

  return deviceType;
};

export default useDeviceType;
