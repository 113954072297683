import { useEffect, useRef } from 'react';
import { Howl, Howler } from 'howler';
import { useGame } from '../context/GameContext';
import { toast } from 'react-toastify';
import { audioActiveSVG } from '../resourcesBase64/images/icons';

const useAudioPlayer = (src: string) => {
  const soundRef = useRef<Howl | null>(null);
  const currentTimeRef = useRef(0); // Для хранения текущего времени
  const { isAudioMuted } = useGame();

  const KEY = 'aeroflot';

  useEffect(() => {
    Howler.mute(isAudioMuted);
    if (isAudioMuted) return;
    console.log('Howler.ctx.state ', Howler.ctx.state);
    console.log('soundRef.current?.playing() ', soundRef.current?.playing());

    if (Howler.ctx.state === 'suspended' && soundRef.current && !soundRef.current.playing())
      toast.info('Коснитесь экрана, чтобы услышать музыкальное сопровождение.', {
        icon: <img src={audioActiveSVG} alt="звук" />,
        toastId: 'audio',
      });
  }, [isAudioMuted]);

  useEffect(() => {
    // Инициализация звука
    const newSound = new Howl({
      src: [src],
      loop: true,
      volume: 0.03,
      // autoplay: true,
      // html5: true, // Поддержка HTML5 для воспроизведения в браузерах
      // onplay: () => {
      //   requestAnimationFrame(updateTime); // Запуск обновления времени
      // },
    });

    soundRef.current = newSound;
    console.log('newSound ', newSound);

    // Загрузка сохраненного времени из localStorage
    const savedTime = sessionStorage.getItem(`${KEY}-soundTime`);
    if (savedTime) {
      console.log('savedTime ', savedTime);
      newSound.seek(parseFloat(savedTime)); // Установка времени на сохраненное
    }

    newSound.play(); // Автоматически начинаем воспроизведение

    return () => {
      console.log('Очищаем звук');
      if (newSound.playing()) {
        const currentTime = newSound.seek();
        currentTimeRef.current = currentTime;
        console.log('currentTime ', currentTime);
        sessionStorage.setItem(`${KEY}-soundTime`, `${currentTime}`); // Сохранение времени
      }
      // Очищаем звук при размонтировании компонента
      newSound.unload();
    };
  }, [src]);

  return { currentTime: () => (soundRef.current ? soundRef.current.seek() : 0) };
};

export default useAudioPlayer;
