import React, { createContext, useContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { loadContext, saveContext } from '../redux/store/browser-storage';

interface GameContextType {
  isFirstGame: boolean;
  isAudioMuted: boolean;
  isReferralUsed: boolean;
  score: number;
  lives: number;
  promoCodes: string[];
  saveIsFirstGame: (flag: boolean) => void;
  toggleIsAudioMuted: () => void;
  saveIsReferralUsed: (flag: boolean) => void;
  saveScore: (score: number) => void;
  saveLives: (lives: number) => void;
  savePromoCodes: (promoCodes: string[]) => void;
}

const GameContext = createContext<GameContextType | undefined>(undefined);

interface GameProviderProps {
  children: ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({ children }) => {
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(() => {
    const savedIsAudioMuted = loadContext('isAudioMuted');
    return savedIsAudioMuted ?? false;
  });
  const [isFirstGame, setIsFirstGame] = useState<boolean>(() => {
    const savedIsFirstGame = loadContext('isFirstGame');
    return savedIsFirstGame ?? true;
  });
  const [isReferralUsed, setIsReferralUsed] = useState<boolean>(() => {
    const savedIsReferralUsed = loadContext('isReferralUsed');
    return savedIsReferralUsed ?? false;
  });
  const [score, setScore] = useState<number>(() => {
    const savedScore = loadContext('score');
    return savedScore ?? 0;
  });
  const [lives, setLives] = useState<number>(() => {
    const savedLives = loadContext('lives');
    return savedLives ?? 0;
  });
  const [promoCodes, setPromoCodes] = useState<string[]>(() => {
    const savedPromoCodes = loadContext('promoCodes');
    return savedPromoCodes ?? null;
  });

  const toggleIsAudioMuted = useCallback(() => {
    return setIsAudioMuted((prev) => {
      saveContext('isAudioMuted', !prev);
      return !prev;
    });
  }, []);

  const saveIsFirstGame = useCallback((flag: boolean) => {
    setIsFirstGame(() => {
      saveContext('isFirstGame', flag);
      return flag;
    });
  }, []);

  const saveIsReferralUsed = useCallback((flag: boolean) => {
    setIsReferralUsed(() => {
      saveContext('isReferralUsed', flag);
      return flag;
    });
  }, []);

  const saveLives = useCallback((lives: number) => {
    setLives(() => {
      saveContext('lives', lives);
      return lives;
    });
  }, []);

  const saveScore = useCallback((score: number) => {
    setScore(() => {
      saveContext('score', score);
      return score;
    });
  }, []);

  const savePromoCodes = useCallback((promoCodes: string[]) => {
    setPromoCodes(() => {
      saveContext('promoCodes', promoCodes);
      return promoCodes;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      isFirstGame,
      isAudioMuted,
      score,
      lives,
      promoCodes,
      saveIsFirstGame,
      toggleIsAudioMuted,
      saveScore,
      saveLives,
      savePromoCodes,
      isReferralUsed,
      saveIsReferralUsed,
    }),
    [
      isFirstGame,
      isAudioMuted,
      score,
      lives,
      promoCodes,
      saveIsFirstGame,
      toggleIsAudioMuted,
      saveScore,
      saveLives,
      savePromoCodes,
      isReferralUsed,
      saveIsReferralUsed,
    ]
  );

  return <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>;
};

export const useGame = (): GameContextType => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};
