import { generateRandomString } from '../tools/generateRandom';

const userData = {
  username: 'game_user',
  password: 'L*XjH,eE5S3vj~s',
};

const clientId = process.env.REACT_APP_CLIENT_ID;
console.log('clientId:', clientId);
console.log('process.env.NODE_ENV:', process.env.NODE_ENV);

const apiUri = process.env.REACT_APP_API_URL;
const apiUrl = `https://${apiUri}api/`;

const location = window.location;
console.log('location:', location);
const isSecure = location.protocol.includes('https');

const urlList = {
  tokensUrl: `${apiUrl}auth/token/`,
  uuidUrl: `${apiUrl}auth/uuid/`,
  socketUrl: `ws${isSecure ? 's' : ''}://${apiUri}ws/api/game`,
};

const redirectUri = process.env.REACT_APP_REDIRECT_URL;
const redirectUrl = `https://${redirectUri}auth_callback`;
const responseType = 'code id_token token';

const aeroflotUrlList = {
  signIn: (state: string) =>
    clientId
      ? `${process.env.REACT_APP_AEROFLOT_SIGNIN_URL}login?nonce=${generateRandomString(64)}&state=${state}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=${encodeURIComponent(responseType)}&client_id=${clientId}&_preferredLocale=ru&scope=openid+promo-profile&_preferredLanguage=ru`
      : null,
  signUp: process.env.REACT_APP_AEROFLOT_SIGNUP_URL ?? null,
};

export { userData, urlList, apiUrl, aeroflotUrlList };
