import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { mainApi } from '../../services/mainApi';
import { loadState } from '../browser-storage';
import { setExpiry } from '../../../tools/stateExpiration';

export interface IStateParamState {
  value: string | null;
  expiryHalf: number;
  expiryFull: number;
}

export interface ITokensState {
  refresh_token: string | null;
  access_token: string | null;
  user_created: boolean | null;
  can_use_referral_code: boolean | null;
}

export interface IUserState {
  email: string | null;
  nickname: string | null;
}

export interface IAuthState extends ITokensState, IUserState {
  state: IStateParamState | null;
  uuid: string | null;
  isAuthenticated: boolean;
}

const INITIAL_STATE = {
  state: null,
  refresh_token: null,
  access_token: null,
  user_created: false,
  uuid: null,
  email: null,
  nickname: null,
  isAuthenticated: false,
} as IAuthState;

const persistedState = loadState();

const authSlice = createSlice({
  name: 'auth',
  initialState: { ...INITIAL_STATE, ...persistedState },
  reducers: {
    clearStateParam: (state: IAuthState) => {
      state.state = null;
    },
    setUser: (state: IAuthState, action: PayloadAction<IUserState>) => {
      const { email, nickname } = action.payload;
      state.email = email;
      state.nickname = nickname;
    },
    setNickname: (state: IAuthState, action: PayloadAction<Record<'nickname', string>>) => {
      const { nickname } = action.payload;
      state.nickname = nickname;
    },
    clearUuid: (state: IAuthState) => {
      state.uuid = null;
    },
    logOut: (state: IAuthState) => {
      state.refresh_token = null;
      state.access_token = null;
      state.uuid = null;
      state.email = null;
      state.nickname = null;
      state.isAuthenticated = false;
      state.user_created = null;
      state.can_use_referral_code = null;
      state.state = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(mainApi.endpoints.getState.matchFulfilled, (state, { payload }) => {
      const ttl = 60 * 60 * 1000; // ttl - время хранения в миллисекундах - 60 минут в миллисекундах
      const expiryHalf = setExpiry(ttl / 2);
      const expiryFull = setExpiry(ttl);
      state.state = { value: payload.state, expiryHalf, expiryFull };
      console.log('new state ', { value: payload.state, expiryHalf, expiryFull });
    });
    builder.addMatcher(mainApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.refresh_token = payload.refresh_token;
      state.access_token = payload.access_token;
      state.user_created = payload.user_created;
      state.can_use_referral_code = payload.can_use_referral_code;
      state.isAuthenticated = true;
      state.state = null;
    });
    builder.addMatcher(mainApi.endpoints.loginCredentials.matchFulfilled, (state, { payload }) => {
      state.refresh_token = payload.refresh;
      state.access_token = payload.access;
      state.isAuthenticated = true;
    });
    builder.addMatcher(mainApi.endpoints.refresh.matchFulfilled, (state, { payload }) => {
      state.access_token = payload.access;
      state.isAuthenticated = true;
      state.uuid = '';
    });
    builder.addMatcher(mainApi.endpoints.getUuid.matchFulfilled, (state, { payload }) => {
      state.uuid = payload.uuid;
    });
  },
});

export const { clearStateParam, setUser, setNickname, clearUuid, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentIsAuthenticated = (state: RootState) => state!.auth.isAuthenticated;

export const selectCurrentState = (state: RootState) => state!.auth.state;

export const selectCurrentUuid = (state: RootState) => state!.auth.uuid;

export const selectCurrentCanUseReferral = (state: RootState) => state!.auth.can_use_referral_code;

export const selectCurrentNickname = (state: RootState) => state!.auth.nickname;
