import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../shared/redux/services/mainApi';
import generateHMAC from '../../shared/tools/generateHMAC';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateParam, selectCurrentState } from '../../shared/redux/store/auth/authSlice';
import { isExpired } from '../../shared/tools/stateExpiration';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { isTokenExpired } from '../../shared/tools/parseJwt';
import './index.scss';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const stateStored = useSelector(selectCurrentState);
  const [mounted, setMounted] = useState(false);
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    // Получаем параметры из URL
    const urlParams = new URLSearchParams(window.location.search); // #access_token=...&id_token=...
    const accessToken = urlParams.get('access_token');
    const stateValue = urlParams.get('state');
    let isFailed = false;
    console.log('window.location ', window.location);
    console.log('accessToken ', accessToken);
    const { value: state, expiryFull } = stateStored || {};
    console.log(
      'stateValue ',
      stateValue,
      ' isExpired(expiryFull)',
      isExpired(expiryFull),
      typeof stateValue,
      typeof state,
      state === stateValue
    );
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    console.log('secretKey:', secretKey);
    if (!secretKey) {
      toast.warn('Ошибка: secret_key не задан');
      return;
    }
    if (accessToken && isTokenExpired(accessToken)) {
      toast.warn('Ошибка: Access токен истек, пройдите авторизацию снова');
      isFailed = true;
    }
    if (stateValue && isExpired(expiryFull)) {
      toast.warn('Ошибка: state истек, пройдите авторизацию снова');
      isFailed = true;
    }
    if (stateValue && stateValue !== state) {
      toast.warn('Ошибка: state не равен переданному ранее значению, пройдите авторизацию снова');
      isFailed = true;
    }
    if (isFailed) {
      dispatch(clearStateParam());
      return;
    }

    if (accessToken && stateValue) {
      if (isLoading) return;

      const getTokens = async () => {
        const hmac = await generateHMAC(secretKey, accessToken);
        console.log('HMAC:', hmac);
        if (!hmac) {
          toast.warn('Ошибка: не можем сгенерировать key для авторизации');
          return;
        }

        const fdata = new FormData();
        fdata.append('access_token', accessToken);
        fdata.append('state', stateValue);
        fdata.append('key', hmac);

        console.log('fdata ', fdata);

        await login(fdata)
          .unwrap()
          .then((authData: any) => {
            const { user_created } = authData;
            navigate(user_created ? '/registration' : '/rules');
          })
          .catch((error) => {
            console.log('error ', error);
            console.log('error.data ', error.data);
          });
      };

      getTokens();
    } else {
      console.error('Access токен or state не найдены в URL.');
      // Обработка ошибки, если токены не были найдены
    }
  }, [mounted]);

  return <h2>Выполняем авторизацию...</h2>;
};

export default AuthCallback;
