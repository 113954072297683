import { Routing } from 'pages';
import { withProviders } from './providers';
import { GameProvider } from '../shared/context/GameContext';
import { WebSocketProvider } from '../shared/context/WebSocketContext';
import { PromoCodesProvider } from '../shared/context/PromoCodesContext';
import { ToastContainer } from 'react-toastify';
import './index.scss';

const App = () => {
  return (
    <div className="app">
      <GameProvider>
        <WebSocketProvider>
          <PromoCodesProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              closeOnClick
              draggable
              pauseOnHover
              theme="light"
            />
            <Routing />
          </PromoCodesProvider>
        </WebSocketProvider>
      </GameProvider>
    </div>
  );
};

export default withProviders(App);
