import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'shared/config/config';
import { RootState } from '../store/store';
import { logOut } from '../store/auth/authSlice';
import { removeContexts, saveState } from '../store/browser-storage';
import { isTokenExpired } from '../../tools/parseJwt';
import { RefreshResponse } from './mainApi';

const baseQuery = fetchBaseQuery({ baseUrl: apiUrl });

// Тип для данных при ошибке
export interface CustomError {
  status: number | 'FETCH_ERROR';
  data: {
    message: string;
  };
}

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs, // Аргументы
  unknown, // Тип данных ответа (можно указать конкретный тип)
  FetchBaseQueryError | CustomError // Тип ошибки
> = async (args, api, extraOptions) => {
  console.log('args ', args, ' api ', api, ' extraOptions ', extraOptions);

  const { endpoint } = api;
  if (endpoint === 'getState' || endpoint === 'login' || endpoint === 'loginCredentials') {
    return baseQuery(args, api, extraOptions);
  }

  let access = (api.getState() as RootState).auth.access_token;

  console.log('access ', access);
  console.log('isTokenExpired(access) ', isTokenExpired(access));
  if (!access || isTokenExpired(access)) {
    let message;
    const refresh = (api.getState() as RootState).auth.refresh_token;
    console.log('refresh ', refresh);
    console.log('isTokenExpired(refresh) ', isTokenExpired(refresh));

    if (!refresh) {
      message = 'Токены не найдены';
    }

    if (isTokenExpired(refresh)) {
      message = 'Refresh токен истек';
    }

    if (!message) {
      const refreshResult = await baseQuery(
        {
          url: 'auth/token/refresh/',
          method: 'POST',
          body: JSON.stringify({ refresh }),
          headers: { 'Content-Type': 'application/json' },
        },
        { ...api },
        extraOptions
      );

      if (refreshResult?.data) {
        removeContexts();
        access = (refreshResult?.data as RefreshResponse).access;
        console.log('new access ', access);
      } else {
        message = 'Не удалось обновить access токен';
      }
    }

    if (message) {
      api.dispatch(logOut());
      saveState((api.getState() as RootState)?.auth);
      removeContexts();
      localStorage.removeItem('promoCodeInfoFinal');
      sessionStorage.removeItem('promoCodes');
      return {
        error: { status: 401, data: { message: `${message}, требуется повторный логин` } },
      };
    }
  }

  // Возвращаем исходный запрос с новым accessToken
  // Преобразование запроса, если это строка
  const request =
    typeof args === 'string'
      ? { url: args, headers: { authorization: `Bearer ${access}` } } // Если это строка (GET-запрос)
      : { ...args, headers: { ...args.headers, authorization: `Bearer ${access}` } }; // Если это объект

  return baseQuery(request, api, extraOptions);
};

// Define a service using a base URL and expected endpoints
export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: ['State', 'Access', 'Refresh', 'UserCreated', 'Email', 'Nickname', 'Results', 'Uuid'],
});
