import { useState, useEffect, memo } from 'react';
import { Tooltip } from 'react-tooltip';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'shared/ui/Input/Input';
import { RulesModal } from 'shared/ui/RulesModal/RulesModal';
import Modal from 'shared/ui/Modal/Modal';
import { useLazyGetUuidQuery } from 'shared/redux/services/mainApi';
import { urlList } from 'shared/config/config';
import { useWebSocket, webSocketMessages, ReadyState } from 'shared/context/WebSocketContext';
import { useGame } from 'shared/context/GameContext';
import Loader from 'shared/ui/Loader/Loader';
import { infoSVG } from 'shared/resourcesBase64/images/icons';
import { useAuth } from 'shared/redux/hooks/useAuth';
import { outOfGameMP3 } from 'shared/resourcesBase64/sounds/sounds';
import GameInfo from 'shared/ui/GameInfo/GameInfo';
import useAudioPlayer from 'shared/hooks/useAudioPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { clearUuid, selectCurrentCanUseReferral } from 'shared/redux/store/auth/authSlice';
import { toast } from 'react-toastify';
import generateHMAC from 'shared/tools/generateHMAC';
import './index.scss';

interface ReferralCodeForm {
  referral: string;
}

const InfoBlock = memo(() => (
  <div className="info-wrapper">
    <button data-tooltip-id="info-tooltip" className="info-icon" data-tooltip-variant="light">
      <img src={infoSVG} alt="что это?" />
    </button>
    <Tooltip
      id="info-tooltip"
      className="tooltip"
      opacity={1}
      place={'top-start'}
      classNameArrow="info-arrow"
      offset={15}
    >
      <div>
        Реферальный код — это набор символов, которые вам отправил ваш знакомый или друг. Если у вас
        нет реферального кода, просто возвращайтесь в игру.
      </div>
      <div>
        Если вы захотите поделиться реферальным кодом со своими друзьями, загляните в личный кабинет
        игры.
      </div>
    </Tooltip>
  </div>
));

const Rules = memo(() => {
  const isAuthenticated = useAuth();
  const canUseReferral = useSelector(selectCurrentCanUseReferral);
  const [trigger, { data, isLoading, isFetching }] = useLazyGetUuidQuery();
  const { currentTime } = useAudioPlayer(outOfGameMP3);
  const { isFirstGame, saveScore, isReferralUsed, saveIsReferralUsed } = useGame();

  const {
    connected,
    sendMessage,
    lastMessage,
    connectionStatus,
    setWebSocketUrl,
    readyState,
    saveShouldReconnect,
    currentUrl,
  } = useWebSocket();

  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<UnknownAction> = useDispatch();
  const [isOpenRules, setIsOpenRules] = useState(false);
  const [isOpenReferral, setIsOpenReferral] = useState(false);
  const { control, handleSubmit } = useForm<ReferralCodeForm>({
    defaultValues: {
      referral: '',
    },
  });

  const [mounted, setMounted] = useState(false);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    setMounted(true);
    dispatch(clearUuid());
    setWebSocketUrl('');

    trigger().then(async (data) => {
      if (!data?.data) return;

      const { uuid } = data.data;
      if (!uuid) return;

      const secretKey = process.env.REACT_APP_SECRET_KEY;
      console.log('secretKey:', secretKey);
      if (!secretKey) {
        toast.warn('Ошибка: secret_key не задан. Не можем приготовить игру...');
        return;
      }

      const hmac = await generateHMAC(secretKey, uuid);
      console.log('HMAC:', hmac);
      if (!hmac) {
        toast.warn('Ошибка: не можем сгенерировать key для игры');
        return;
      }

      const wsUrl = `${urlList.socketUrl}?uuid=${uuid}&key=${hmac}`;
      setTriggered(true);
      setWebSocketUrl(wsUrl);
      saveShouldReconnect(true);
    });
  }, []);

  useEffect(() => {
    if (!lastMessage || isReferralUsed || !canUseReferral || !isFirstGame) return;

    const { message } = lastMessage;
    if (typeof message !== 'string') return;

    if (message.includes('OK')) {
      saveScore(300);
      saveIsReferralUsed(true);
      setIsOpenReferral(false);
      toast.success('Реферальный код применен', {
        toastId: 'referralSuccess',
      });
    }
    if (message.includes('limit') && message.includes('not found')) {
      toast.error('Вы уже применяли этот код!', {
        toastId: 'referralError',
      });
    }
    console.log('lastMessage ', lastMessage);
  }, [lastMessage]);

  const handleClick = () => {
    if (!isAuthenticated || !connected || readyState !== ReadyState.OPEN || !currentUrl) return;

    navigate('/game');
  };

  const onSubmit = (data: ReferralCodeForm) => {
    console.log(data);
    if (!data.referral || !connected || !isFirstGame) return;

    sendMessage({ action: webSocketMessages.ADD_REFERRAL_CODE, value: data.referral });
    toast.info('Пытаемся применить реферальный код', {
      toastId: 'referral',
    });
  };

  return (
    <div className="rules-container">
      {/*<span style={{ position: 'absolute', top: 0, right: 5, fontSize: 15 }}>*/}
      {/*  The WebSocket is currently {connectionStatus}*/}
      {/*</span>*/}
      <GameInfo isAudioOnly={true} />
      <Title type={'h1'} text="Андрей опаздывает!" />
      <Text
        classNames={['navigation-text']}
        text="Избегайте преград и лужи. Чтобы перепрыгнуть препятствие, нажмите левую кнопку мыши или кликните по&nbsp;экрану смартфона пальцем."
      />
      <Text
        classNames={['navigation-text']}
        text="Собирайте игровые мили и рюкзачки — это хорошие предметы, за&nbsp;которые начисляются баллы или замедляется скорость. Если в&nbsp;рюкзачке спрятан промокод от&nbsp;партнера или «Аэрофлот Бонус», он появится в&nbsp;личном кабинете игры"
      />
      <Button
        {...(triggered &&
          !connected && {
            icon: <Loader />,
          })}
        isDisabled={!connected}
        onClick={handleClick}
        classNames={['navigation-button']}
        label={`${triggered && !connected ? 'Готовим игру...' : 'Играть!'}`}
      />
      {canUseReferral && !isReferralUsed && isFirstGame && (
        <div className="link-wrapper">
          <button
            className="info-icon-container link"
            onClick={() => setIsOpenReferral((prev) => !prev)}
          >
            У меня есть реферальный код!
          </button>
          <InfoBlock />
        </div>
      )}
      <button className="short-rules link" onClick={() => setIsOpenRules(true)}>
        Краткие правила акции и призовой фонд
      </button>
      {mounted && (
        <RulesModal
          isOpen={isOpenRules}
          onClose={() => setIsOpenRules(false)}
          container={document.querySelector('.rules-container') as HTMLElement}
        />
      )}
      {mounted && (
        <Modal
          isOpen={isOpenReferral}
          onClose={() => setIsOpenReferral(false)}
          cls="modal-referral"
          container={document.querySelector('.rules-container') as HTMLElement}
        >
          <form className="referral-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="referral"
              rules={{
                required: 'Введите реферальное слово',
                pattern: {
                  value: /^[a-zA-Z0-9]+$/,
                  message: 'Вы ввели неверный код — попробуйте еще раз.',
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  placeholder="Введите реферальное слово"
                  dangerText={fieldState.error?.message}
                  isDanger={fieldState.invalid}
                  {...field}
                />
              )}
            />
            <Button
              {...(triggered &&
                !connected && {
                  icon: <Loader />,
                })}
              isDisabled={!connected}
              size="medium"
              classNames={['referral-button']}
              label={`${triggered && !connected ? 'Готовим игру...' : 'Применить'}`}
            />
            <div className="link-wrapper">
              <div className="info-icon-container pseudo link">Что такое реферальный код!</div>
              <InfoBlock />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
});

export default Rules;
