import { Ref, useEffect, useRef, memo, useMemo, useLayoutEffect, RefObject } from 'react';
import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Footer from 'shared/ui/Footer/Footer';
import { Title } from 'shared/ui/Title/Title';
import AuthCallback from './authCallback/AuthCallback';
import StartPage from './start';
import RegistrationPage from './registration';
import AccountPage from './account';
import RulesPage from './rules';
import GamePage from './game';
import FinalPage from './final';
import { useNavigate } from 'react-router';
import { useAuth } from 'shared/redux/hooks/useAuth';
import { heroSVG, heroSVGTablet } from 'shared/resourcesBase64/images/hero';
import { logoSVG } from 'shared/resourcesBase64/images/logo';
import { useWebSocket } from 'shared/context/WebSocketContext';
import { useMedia } from 'react-use';
import useDeviceDetection from 'shared/hooks/useDeviceDetection';
import useVisualViewportHeight from 'shared/hooks/useVisualViewportHeight';
import useResizeObserver from 'use-resize-observer';
import { removeContexts } from '../shared/redux/store/browser-storage';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useAuth();
  const { setWebSocketUrl, saveShouldReconnect } = useWebSocket();
  console.log('isAuthenticated ', isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) return;

    navigate('/');
    setWebSocketUrl('');
    saveShouldReconnect(false);
  }, [isAuthenticated]);

  return isAuthenticated ? children : null;
};

export const Routing = () => {
  useVisualViewportHeight();
  const location = useLocation();
  const { pathname: page } = location;
  const { setWebSocketUrl, saveShouldReconnect } = useWebSocket();
  const pageOutsideGameList = ['/', '/auth_callback', '/registration'];
  if (pageOutsideGameList.includes(page)) {
    removeContexts();
    setWebSocketUrl('');
    saveShouldReconnect(false);
  }

  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route path="" element={<StartPage />} />
        <Route path="/auth_callback" element={<AuthCallback />} />
        <Route
          path="/registration"
          element={
            <ProtectedRoute>
              <RegistrationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rules"
          element={
            <ProtectedRoute>
              <RulesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/game"
          element={
            <ProtectedRoute>
              <GamePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/final"
          element={
            <ProtectedRoute>
              <FinalPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export const TwoSidesLayout = memo(
  (props: Readonly<{ children: JSX.Element; rightSideChilden?: JSX.Element }>) => {
    // Используем useLayoutEffect для синхронных обновлений после DOM-рендера
    useLayoutEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, []);

    return (
      <div className="container start-page">
        <div className="left-side">
          {props.children}
          <Title type="h2" text="Наши партнеры:" />
        </div>
        <div className="right-side">
          <img className="hero-image" src={heroSVG} alt="изображение с Андреем" loading="lazy" />
          <img
            className="hero-image-tablet"
            src={heroSVGTablet}
            alt="изображение с Андреем"
            loading="lazy"
          />
          {props.rightSideChilden}
        </div>
      </div>
    );
  }
);

export const FullLayout = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;
  const page = pathname.slice(1);
  const isPageWithHero = page.includes('registration') || page.includes('rules') || page === '';

  const { isDesktop } = useDeviceDetection();
  const isWide = useMedia('(min-width: 1024px)');
  console.log('isDesktop ', isDesktop, isWide, ' isPageWithHero ', isPageWithHero);

  const footerRef = useRef<HTMLDivElement>();
  const { height } = useResizeObserver({
    ref: footerRef as unknown as RefObject<Element>,
  });

  const contentMarginTopRef = useRef<string>('0');
  const divRef = useRef<HTMLDivElement>(null);
  console.log('height ', height);
  console.log('page ', page);

  const MemoizedOutlet = useMemo(() => <Outlet />, [page]);

  // Используем useLayoutEffect для синхронных обновлений после DOM-рендера
  useLayoutEffect(() => {
    const content = document.querySelector('.content');
    contentMarginTopRef.current = content ? window.getComputedStyle(content).marginTop : '0';
    console.log('content ', content, ' contentMarginTopRef.current ', contentMarginTopRef.current);
  }, []);

  // Используем useLayoutEffect для синхронных обновлений после DOM-рендера
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useLayoutEffect(() => {
    if (!height) return;

    if (!(isWide && isDesktop) || !isPageWithHero || !(height && contentMarginTopRef.current))
      return;

    if (divRef.current) {
      const newHeight = `calc(calc(var(--vh, 1vh) * 100) - ${height}px - ${contentMarginTopRef.current})`;
      if (divRef.current.style.height !== newHeight) {
        divRef.current.style.height = newHeight;
      }
    }
  }, [height, isDesktop, isWide, isPageWithHero, contentMarginTopRef.current]);

  return (
    <div className={`layout${page ? '-' + page : ''}`}>
      <div className="container">
        <button className="header" onClick={() => navigate('/')}>
          <img className="logo" src={logoSVG} alt="logo" />
        </button>
      </div>
      <div ref={divRef} className="content">
        {MemoizedOutlet}
      </div>
      <Footer
        ref={footerRef as Ref<HTMLDivElement>}
        isPartnersBlockEnabled={isPageWithHero}
        page={page}
      />
    </div>
  );
});

const NoMatch = () => {
  return (
    <div className="page-empty">
      <h2>Тут ничего нет!</h2>
      <p>
        <Link className="link" to="/">
          Вернуться на главную страницу
        </Link>
      </p>
    </div>
  );
};
