import Marquee from 'react-fast-marquee';
import { Button } from '../Button/Button';
import { FC, forwardRef, memo } from 'react';
import { useNavigate } from 'react-router';
import './Footer.scss';
import {
  askonaSVG,
  barhatnieSezoniSVG,
  comfortPassSVG,
  galladanceSVG,
  gruzovichkoffSVG,
  holodilnikiSVG,
  laviconSVG,
  litresSVG,
  mtsSVG,
  otelloSVG,
  renessansSVG,
  samsonFarmaSVG,
  shevMarketSVG,
  siriusHotelsSVG,
  stoletovSVG,
  tvilSVG,
  unode50SVG,
  vipzalSVG,
} from '../../resourcesBase64/images/footerLogos';

interface FooterProps {
  /**
   * @description Отвечает за отображение блока с партнёрами
   */
  isPartnersBlockEnabled?: boolean;
  /**
   * @description Текущая страница
   */
  page?: string;
}

const Footer = forwardRef<HTMLDivElement, FooterProps>(({ isPartnersBlockEnabled, page }, ref) => {
  return (
    <div className="footer" ref={ref}>
      {isPartnersBlockEnabled && <FooterPartners />}
      <FooterContent page={page} />
    </div>
  );
});

const FooterPartners: FC = memo(() => {
  const logoList = [
    { id: 0, src: askonaSVG, alt: 'Askona' },
    { id: 1, src: barhatnieSezoniSVG, alt: 'Бархатные сезоны' },
    { id: 2, src: comfortPassSVG, alt: 'Comfort pass' },
    { id: 3, src: galladanceSVG, alt: 'Galla Dance' },
    { id: 4, src: gruzovichkoffSVG, alt: 'Грузовичкоф' },
    { id: 5, src: holodilnikiSVG, alt: 'Холодильники.ру' },
    { id: 6, src: laviconSVG, alt: 'Lavicon' },
    { id: 7, src: litresSVG, alt: 'Литрес' },
    { id: 8, src: mtsSVG, alt: 'МТС' },
    { id: 9, src: otelloSVG, alt: 'Отелло' },
    { id: 10, src: renessansSVG, alt: 'Ренессанс страхование' },
    { id: 11, src: samsonFarmaSVG, alt: 'Самсон-фарма' },
    { id: 12, src: shevMarketSVG, alt: 'Шефмаркет' },
    { id: 13, src: siriusHotelsSVG, alt: 'Sirius hotels' },
    { id: 14, src: stoletovSVG, alt: 'Аптека доктор Столетов' },
    { id: 15, src: tvilSVG, alt: 'ТВИЛ' },
    { id: 16, src: unode50SVG, alt: 'UNO de 50' },
    { id: 17, src: vipzalSVG, alt: 'Vip-zal.ru' },
  ];

  return (
    <div className="partners">
      <Marquee autoFill>
        {logoList.map(({ id, src, alt }) => (
          <div className="marquee-item" key={id}>
            <img src={src} alt={alt} />
          </div>
        ))}
      </Marquee>
    </div>
  );
});

interface FooterContentProps {
  /**
   * @description Текущая страница
   */
  page?: string;
}

const FooterContent: FC<FooterContentProps> = memo(({ page }) => {
  const navigate = useNavigate();
  const isAccount = page === 'account';
  const isBtnDisabled = page === '' || page === 'registration';

  return (
    <div className="footer-content">
      <div className="container">
        <div className="left-side">
          <div className="links">
            <a href="/">Правила акции</a>
            <a href="/">Политика конфиденциальности</a>
          </div>
          <span>© Авиакомпания «Аэрофлот» 2008-2024</span>
        </div>
        <div className="right-side">
          <Button
            isOutlined
            label={`${isAccount ? 'Вернуться в игру' : 'Личный кабинет игры'}`}
            isDisabled={isBtnDisabled}
            size="small"
            onClick={() => navigate(`${isAccount ? '/rules' : '/account'}`)}
          />
        </div>
      </div>
    </div>
  );
});

const MemoizedFooter = memo(Footer);

export default MemoizedFooter;
