import {
  combineReducers,
  configureStore,
  createSelector,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import authSlice, { selectCurrentState } from './auth/authSlice';
import { baseApi } from '../services/baseApi';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import debounce from 'debounce';
import { saveState } from './browser-storage';

const reducers = combineReducers({
  auth: authSlice, // This reducer is to set credentials & consume state in application
  [baseApi.reducerPath]: baseApi.reducer, // this reducer has auth header & endpoints
});

// Определим тип данных для payload ошибки
interface RejectedPayload {
  status?: number;
  data?: unknown; // Данные ответа от сервера
  error?: string; // Сообщение об ошибке
}

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // Проверка, что запрос был отклонен с использованием rejectWithValue
  if (isRejectedWithValue(action)) {
    const payload = action.payload as RejectedPayload;
    console.error('Ошибка запроса:', payload);

    const status = payload.status;
    const data = payload.data;
    const errorMessage =
      (payload.error ?? (data as Record<'error', string>).error) ||
      (data as Record<'message', string>).message ||
      'Произошла ошибка';
    toast.error(`Ошибка: ${status} - ${errorMessage}`);
    console.error('Данные ответа:', data);
  }

  return next(action);
};

const store = configureStore({
  reducer: reducers,
  // preloadedState: loadState(),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware, rtkQueryErrorLogger),
});

const stateParamSelector = createSelector([selectCurrentState], (someField) => someField);

let previousValue = stateParamSelector(store.getState());

store.subscribe(() => {
  const currentValue = stateParamSelector(store.getState());
  if (previousValue !== currentValue) {
    console.log('stateParam изменилось:', currentValue);
    previousValue = currentValue;
    saveState(store.getState().auth);
  } else {
    // we use debounce to save the state once each 800ms
    // for better performances in case multiple changes occur in a short time
    debounce(() => {
      saveState(store.getState().auth);
    }, 800);
  }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
