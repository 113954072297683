import React, { FC } from 'react';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useLazyGetStateQuery, useLoginCredentialsMutation } from 'shared/redux/services/mainApi';
import { aeroflotUrlList, userData } from 'shared/config/config';
import { openNewSafeWindow } from 'shared/tools/openNewSafeWindow';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateParam, selectCurrentState } from '../../shared/redux/store/auth/authSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { isExpired } from '../../shared/tools/stateExpiration';

const Navigation: FC = () => {
  const [loginCredentials] = useLoginCredentialsMutation();
  const navigate: NavigateFunction = useNavigate();
  const stateStored = useSelector(selectCurrentState);
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  const [trigger, { data, isLoading, isFetching }] = useLazyGetStateQuery();

  const handleSignIn: () => void = async () => {
    const { value: state, expiryHalf, expiryFull } = stateStored || {};
    let state_value = state;

    console.log(
      'isExpired(expiryHalf) ',
      isExpired(expiryHalf),
      ' isExpired(expiryFull)',
      isExpired(expiryFull),
      ' isNotExpired(expiryHalf)'
    );

    if (state_value && isExpired(expiryHalf)) {
      console.log('isExpired state ', state_value);
      dispatch(clearStateParam());
      state_value = null;
    }
    if (!state_value) {
      try {
        const data = await trigger();
        state_value = data.data?.state;
        console.log('new state ', state_value);
      } catch (e) {
        console.error(e);
        return;
      }
    }
    const signInUrl = aeroflotUrlList.signIn(state_value);
    if (!signInUrl) {
      toast.warn('Ошибка: client_id не задан');
      return;
    }

    window.location.href = signInUrl;
  };

  // const handleSignUp: () => void = async () => {
  const handleSignUp = () => {
    // TODO: uncomment this after redirect link from Aeroflot.bonus after signIn is working
    const signUpUrl = aeroflotUrlList.signUp;
    if (!signUpUrl) {
      toast.warn('Ошибка: адрес регистрации не задан');
      return;
    }

    openNewSafeWindow(signUpUrl);

    // if (isLoading) return;
    //
    // await loginCredentials({ username: userData.username, password: userData.password })
    //   .unwrap()
    //   .then(() => {
    //     navigate('/registration');
    //   })
    //   .catch((error) => {
    //     console.log('error ', error);
    //     console.log('error.data ', error.data);
    //   });
  };

  return (
    <div className="navigation">
      <Title type={'h1'} text="В погоне за милями" />
      <Text
        classNames={['navigation-text']}
        text="Помогите Андрею успеть на рейс, наберите как можно больше баллов и станьте победителем"
      />
      <Button onClick={handleSignIn} classNames={['navigation-button']} label="Войти в игру" />
      <Text
        classNames={['navigation-text']}
        size="small"
        text="Если вы участник программы «Аэрофлот Бонус», нажмите на кнопку"
      />
      <Button
        onClick={handleSignUp}
        classNames={['navigation-button']}
        label="Зарегистрироваться"
      />
      <Text
        size="small"
        text="Если вы еще не зарегистрировались в программе «Аэрофлот Бонус», пройдите регистрацию и возвращайтесь в игру"
      />
    </div>
  );
};

export default Navigation;
